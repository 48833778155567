<template>
  <v-container fluid>
    <v-stepper v-model="e1" class="stepper">
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1"> </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 2" step="2"> </v-stepper-step>

        <!-- <v-divider></v-divider>

        <v-stepper-step step="3"> </v-stepper-step> -->
      </v-stepper-header>

      <v-stepper-items>
        <!-- * For step 1 choose category and name -->
        <v-stepper-content step="1">
          <!-- Category Part -->
          <h4 class="px-5 font-weight-bold text-h5" style="padding-top: 2%;">
            Category
          </h4>
          <h6
            class="pt-1 pl-5  font-weight-regular text-subtitle-1"
            style="padding-top: 2%;padding-bottom: 2%;"
          >
            Choose a category that describes your message template
          </h6>

          <div class="pl-5">
            <div
              v-for="(item, index) in categoryItems"
              :key="index"
              class="radio-card"
              :style="{
                backgroundColor: isSelected(item) ? '#50d56d' : '#EEEEEE',
                paddingTop: '20px',
              }"
            >
              <input
                :id="'radio' + index"
                v-model="selectedItemCategory"
                type="radio"
                :value="item"
                class="radio-input"
                return-object
              />
              <label :for="'radio' + index" class="radio-label">
                <v-icon>{{ item.icon }}</v-icon>
                <div style="padding-left: 2%;">
                  <h4>{{ item.title }}</h4>
                  <h6 class="description">{{ item.description }}</h6>
                </div>
              </label>
            </div>
          </div>
          <!-- Name Part -->
          <h4 class="px-5 font-weight-regular" style="padding-top: 2%;">
            Name
          </h4>
          <h6
            class="pt-1 pl-5 font-weight-regular"
            style="padding-top: 2%;padding-bottom: 1%;"
          >
            Name your message template
          </h6>

          <v-text-field
            v-model="template.title"
            placeholder="Title"
            class="pt-5 px-md-5"
            solo-inverted
            flat
            dense
            clearable
          >
          </v-text-field>

          <div class="px-5">
            <v-btn color="primary" @click="validateStep1">
              Continue
            </v-btn>
          </div>

          <!-- <v-btn text>
            Cancel
          </v-btn> -->
        </v-stepper-content>

        <!-- * Step 2 choose template type and submit -->

        <v-stepper-content step="2">
          <v-row>
            <v-flex xs12 sm8>
              <!-- Header -->
              <h4 class="px-5 font-weight-regular" style="padding-top: 2%;">
                Header
                <span style="padding-left: 10px;font-size: 10px;"
                  >Optional</span
                >
              </h4>
              <h6
                class="pt-1 pl-5  font-weight-regular"
                style="padding-top: 2%;padding-bottom: 1%;"
              >
                Add a title or choose which type of media you'll use for this
                header
              </h6>

              <v-autocomplete
                v-model="template.type"
                autocomplete="off"
                :items="types"
                item-text="text"
                item-value="name"
                class="pt-5 px-md-5"
                solo-inverted
                flat
                dense
                style="max-width: fit-content;"
                @change="checkType"
              />

              <h4
                v-if="template.type == 'image'"
                class="px-5 font-weight-regular"
                style="padding-top: 2%;"
              >
                Image Link *
              </h4>
              <!-- Header text input -->
              <v-text-field
                v-if="template.type == 'text'"
                v-model="template.header"
                placeholder="Header"
                class="pt-5 px-md-5"
                solo-inverted
                flat
                dense
                clearable
              >
              </v-text-field>
              <!-- Header Image input -->
              <v-file-input
                v-if="template.type == 'image'"
                :rules="rules"
                accept="image/*"
                class="pt-5 px-md-5"
                placeholder="Choose an image"
                prepend-icon="mdi-camera"
                label="Avatar"
                solo-inverted
                flat
                dense
                clearable
                @change="onFileSelect"
              ></v-file-input>

              <!-- Body -->
              <h4 class="px-5 font-weight-regular" style="padding-top: 2%;">
                Body *
              </h4>
              <h6
                class="pt-1 pl-5  font-weight-regular"
                style="padding-top: 2%;padding-bottom: 2%;"
              >
                Enter the text for your message
              </h6>

              <v-flex xs12 sm12 class="px-5">
                <div>
                  <v-textarea
                    id="templateArea"
                    ref="templateArea"
                    v-model="template.text"
                    placeholder="Enter your message here."
                    prepend-inner-icon="text_format"
                    outlined
                    rows="3"
                    auto-grow
                    @input="logRawData"
                  ></v-textarea>

                  <!-- Template variables -->
                  <div
                    class="text-right d-flex align-start flex-wrap custom-width"
                  >
                    <div class="value-select">
                      <v-select
                        v-model="selectedField"
                        prepend-inner-icon="input"
                        outlined
                        dense
                        :items="fields"
                      ></v-select>
                    </div>
                    <div class="ml-2">
                      <v-btn height="2.4rem" label @click="addVariables">
                        <v-icon left>
                          add
                        </v-icon>
                        Add a variable
                      </v-btn>
                    </div>
                  </div>
                </div>
              </v-flex>

              <!-- Footer -->
              <h4 class="px-5 font-weight-regular" style="padding-top: 2%;">
                Footer
                <span style="padding-left: 10px;font-size: 10px;"
                  >Optional</span
                >
              </h4>
              <h6
                class="pt-1 pl-5 font-weight-regular"
                style="padding-top: 2%;padding-bottom: 1%;"
              >
                Add a short line or text to the bottom of your message
              </h6>

              <v-text-field
                v-model="template.footer"
                placeholder="Footer"
                class="pt-5 px-md-5"
                solo-inverted
                flat
                dense
                clearable
              >
              </v-text-field>

              <!-- Call to action buttons -->

              <h4 class="px-5 font-weight-regular" style="padding-top: 2%;">
                Interactive Buttons
              </h4>

              <h6
                class="pt-1 pl-5 font-weight-regular"
                style="padding-top: 2%;padding-bottom: 1%;"
              >
                Along with your message, you can also include actions.
              </h6>

              <v-flex xs12 sm12 class="px-5">
                <v-radio-group
                  v-model="template.buttons"
                  mandatory
                  row
                  @change="buttonChange"
                >
                  <v-radio label="None" value="none"></v-radio>
                  <v-radio label="Call to action" value="cta"></v-radio>
                  <v-radio label="Quick Reply" value="qr"></v-radio>
                </v-radio-group>
              </v-flex>
              <!-- Add buttons -->
              <v-flex
                v-if="template.buttons != null && template.buttons != 'none'"
                xs12
                sm12
                class="px-5"
              >
                <div v-for="(item, index) in template.buttonsData" :key="index">
                  <div class="d-flex align-center">
                    <p class="ma-0 custom-btn-width">
                      {{ template.buttons }} {{ index + 1 }}:
                    </p>
                    <div class="d-flex align-start justify-space-betweeen">
                      <div
                        class="d-flex align-start flex-wrap justify-space-betweeen btn-component"
                      >
                        <!-- Button Type -->
                        <v-select
                          v-if="template.buttons == 'cta'"
                          v-model="item.type"
                          :items="ctaType"
                          item-text="name"
                          item-value="value"
                          label="Button Type"
                          class="mx-1"
                          outlined
                          required
                        ></v-select>
                        <!-- Button Title -->
                        <v-text-field
                          v-model="item.text"
                          class="mx-1"
                          :rules="nameRules"
                          :counter="25"
                          :maxlength="25"
                          label="Button Title"
                          outlined
                          required
                        ></v-text-field>
                        <!-- URL link -->
                        <v-text-field
                          v-if="item.type == 'URL'"
                          v-model="item.url"
                          class="mx-1"
                          label="URL link"
                          outlined
                          required
                        ></v-text-field>
                        <!-- Number Input -->
                        <div class="tel-input">
                          <vue-tel-input
                            v-if="item.type == 'PHONE_NUMBER'"
                            v-model="item.phone_number"
                            mode="international"
                            :valid-characters-only="true"
                            :default-country="defaultCountry"
                            class="secondary--text mx-1 tel-input"
                            outlined
                          ></vue-tel-input>
                        </div>
                      </div>
                      <v-btn
                        text
                        x-large
                        class="close-btn"
                        @click="template.buttonsData.splice(index, 1)"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>
                <v-btn class="mt-1" @click="addButtonData">
                  <v-icon>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </v-flex>
            </v-flex>

            <!-- Message Preview -->
            <v-flex xs12 sm4>
              <v-row style="margin: 0px; padding: 20px">
                <div>
                  <v-row class="pa-0 ma-0">
                    <v-flex class="px-md-10">
                      <v-card class="chat-card">
                        <div
                          v-if="template.type == 'image'"
                          class="image-preview"
                        >
                          <img
                            class=""
                            src="../../assets/broken-img.svg"
                            alt="broken-img"
                          />
                        </div>
                        <v-card-text
                          v-if="template.type == 'text'"
                          class="pa-0 font-weight-bold"
                          >{{ template.header }}</v-card-text
                        >
                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <v-card-text class="chat-text" v-html="safeHtml" />

                        <v-card-text
                          v-if="template.footer != ''"
                          class="pa-0 chat-footer"
                          >{{ template.footer }}</v-card-text
                        >
                        <v-card-actions
                          v-if="
                            template.buttons != null &&
                              template.buttons != 'none'
                          "
                          class="chat-actions pa-0"
                        >
                          <v-btn
                            v-for="(item, index) in template.buttonsData"
                            :key="index"
                            small
                            block
                            class="c-blue my-1 mx-0"
                          >
                            {{ item.text }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-flex>
                  </v-row>
                </div>
              </v-row>
            </v-flex>

            <v-flex xs12 sm4 class="pa-5">
              <v-btn color="primary" @click="saveTemplate">
                Save
              </v-btn>

              <v-btn text @click="goBack">
                Back
              </v-btn>
            </v-flex>
          </v-row>
        </v-stepper-content>

        <!-- <v-stepper-content step="3">
          <v-card class="mb-12" color="grey lighten-1" height="200px"></v-card>

          <v-btn color="primary" @click="e1 = 1">
            Continue
          </v-btn>

        </v-stepper-content> -->
      </v-stepper-items>
    </v-stepper>

    <v-dialog v-model="loader" persistent width="300">
      <v-card color="primary white--text" dark>
        <v-card-text style="padding: 18px 25px">
          <h3 style="margin-bottom: 5px; font-weight: 400; font-size: 16px">
            Please Wait...
          </h3>
          <div style="text-align:center">
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- snackbar -->
    <!-- <v-snackbar
      v-model="snackbar"
      :vertical="true"
      :color="color"
      multi-line
      :transition="true"
    >
      {{ text }}
      <v-btn color="white" text @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar> -->
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import compress from "compress-base64";
import DOMPurify from "dompurify";
import countries from "../../js/countries";
import Vue from "vue";
// import Breadcrumbs from "@/components/breadcrumbs.vue";

export default {
  components: {
    // Breadcrumbs,
  },
  data() {
    return {
      //snackbar
      e1: 1,
      selectedItemCategory: null,
      categoryItems: [
        {
          icon: "mdi-shopping",
          title: "Marketing",
          description:
            "Send promotion and information about your products, services or business.",
        },
        {
          icon: "mdi-account-switch",
          title: "Utility",
          description: "Send message about an existing order or an account.",
        },
      ],
      pageSelected: false,
      snackbar: false,
      text: "",
      color: "",
      updateId: null, // id recieved from path query
      preloader: false,
      selectedField: "Name",
      template: {
        text: "Hey user, thank you for choosing GoDial.",
        title: "",
        header: "",
        footer: "",
        desc: "",
        type: "text",
        rawData: null,
        variables: [],
        buttons: "none",
        buttonsData: [],
      },
      ctaType: [
        {
          name: "Phone Number",
          value: "PHONE_NUMBER",
        },
        {
          name: "URL",
          value: "URL",
        },
      ],
      fields: [
        "Agent's Name",
        "Agent's Phone",
        "Agent's Email",
        "Name",
        "Phone",
        "Email",
        "Company",
        // "Remarks",
        // "Note",
      ],
      nameRules: [
        (v) => !!v || "Title is required",
        (v) => v.length <= 25 || "Title must be less than 25 characters",
      ],
      variableItems: ["name", "agentName"],
      allCustomFields: "",
      uploadedPic: null,
      attachmentURL: "",
      loader: false,
      fileToUpload: null,
      types: [
        {
          text: "Text",
          name: "text",
          selected: true,
          icon: "mdi-message-text",
        },
        {
          text: "Image",
          name: "image",
          selected: false,
          icon: "mdi-message-text",
        },
        // {
        //   text: "Image",
        //   name: "image",
        //   selected: false,
        //   icon: "mdi-message-image",
        // },
        // {
        //   text: "Image and Text",
        //   name: "both",
        //   selected: false,
        //   icon: "mdi-image-edit",
        // },
        // { text: "Link", name: "link", selected: false, icon: "mdi-link" },
      ],
      filelist: [],
      style: "",
      rules: [
        (value) =>
          !value ||
          value.size < 5000000 ||
          "Avatar size should be less than 2 MB!",
      ],
    };
  },
  computed: {
    ...mapGetters(["ENDPOINT", "fieldList"]),
    safeHtml() {
      const data = this.template.rawData
        ? JSON.parse(this.template.rawData)
        : this.template.text;
      return DOMPurify.sanitize(this.compileText(data));
    },
    defaultCountry() {
      const country = countries.find(
        (item) => item.name === this.$store.state.companyDetails.country
      );
      return country ? country.code : "IN";
    },
  },
  mounted() {
    this.updateId = this.$router.currentRoute.params.id;

    this.pageSelected = this.$route.query.page;

    this.getAllCustomFields();
    if (this.updateId != "add" && this.updateId != "") {
      this.getTemplateBy(this.updateId);
    }

    this.selectedItemCategory = this.categoryItems[0];
    this.logRawData(this.template.text);

    console.log("this.template", this.template);
  },
  methods: {
    clearButtonsData() {
      this.template.buttonsData = [];
    },
    addButtonData() {
      switch (this.template.buttons) {
        case "cta":
          this.template.buttonsData.push({
            type: "URL",
            text: "",
            url: "",
            phone_number: "",
          });
          break;
        case "qr":
          this.template.buttonsData.push({
            type: "QUICK_REPLY",
            text: "",
          });
          break;
        default:
          break;
      }
      console.log("this.template: ", this.template);
    },
    buttonChange() {
      this.clearButtonsData();
      this.addButtonData();
    },
    addImage(file) {
      const formData = new FormData();
      formData.append("file", file);

      console.log("image data: ", formData);
    },
    addVariables() {
      this.template.text = `${this.template.text}{{${this.selectedField}}}`;
      let rawData = `${JSON.parse(this.template.rawData)}{{${
        this.selectedField
      }}}`;
      this.template.rawData = JSON.stringify(rawData);
      this.template.variables.push(this.selectedField);
      console.log("template variables: ", this.template.variables);
    },
    extractItems(inputString) {
      const regex = /{{(.*?)}}/g;
      let matches = [];
      let match;

      while ((match = regex.exec(inputString)) !== null) {
        matches.push(match[1]);
      }

      return matches;
    },
    formatForHtml(text) {
      // console.log("replace 1");
      return text.replace(/\n/g, "<br>");
    },
    logRawData(newValue) {
      // Log the new value for debugging
      console.log("Raw text area value: ", newValue);

      // Store the updated raw data in JSON format
      this.template.rawData = JSON.stringify(newValue);

      console.log("items: ", this.extractItems(this.template.rawData));

      const currentVariables = this.extractItems(this.template.rawData);

      console.log("current variables: ", currentVariables);

      // Filter the original variables array to include only those found in the current text
      this.template.variables = currentVariables;

      console.log("Updated variables: ", this.template.variables);
    },
    checkType() {
      console.log("this.template", this.template);
    },
    async uploadFile(file) {
      try {
        var formData = new FormData();
        formData.append("file", file);
        formData.append("type", "whatsappTemplate");
        formData.append("companyId", this.$store.state.companyDetails.id);
        formData.append("bucketName", "godial-enterprise-export");
        var config = {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: this.$store.state.user.token,
          },
        };

        //store the file to storage
        var attachment = await this.$http.post(
          `${this.$store.state.ENDPOINT}/attachments/uploadv2`,
          formData,
          config
        );
        attachment = attachment.body;
        return attachment;
      } catch (e) {
        this.loader = false;
      }
    },
    async saveTemplate() {
      // Define template data for different template styles
      var self = this;

      self.loader = true;

      const regex = /^[a-z_]+$/;
      if (!regex.test(self.template.title.trim())) {
        return self.$swal({
          type: "error",
          text:
            "Template title must be lowercase and can contain underscores only.",
        });
      }

      // if template buttons has a value other than 'none' and buttonsData is an empty array than change buttons value to none

      if (
        (self.template.buttons != null) & (self.template.buttons != "none") &&
        !self.template.buttonsData.length
      ) {
        self.template.buttons = "none";
        // console.log("this.template.buttons: ", self.template.buttons);
        // console.log("this.template.buttonsData: ", self.template.buttonsData);
      }

      // Check if any button has a missing field if buttons is other than none

      if (self.template.buttons != "none" && self.template.buttonsData.length) {
        self.template.buttonsData.forEach((item) => {
          if (item.text == "") {
            this.$swal({
              type: "error",
              text: "Button Title cannot be blank. Please give a title",
            });
            self.loader = false;
            return;
          }

          if (item.type == "PHONE_NUMBER") {
            if (item.phone_number == "") {
              this.$swal({
                type: "error",
                text:
                  "Phone Number field cannot be blank. Please enter phone number",
              });
              self.loader = false;
              return;
            }
          } else if (item.type == "URL") {
            if (item.url == "") {
              this.$swal({
                type: "error",
                text: "URL field cannot be blank. Please enter url",
              });
              self.loader = false;
              return;
            }
          }
        });
      }

      console.log("After part fired");

      // Sorting and deleting extra property in buttons

      if (this.template.buttons != null && this.template.buttons != "none") {
        this.template.buttonsData.forEach((item) => {
          if (item.type == "PHONE_NUMBER") {
            item.phone_number = item.phone_number.replace(/[^a-zA-Z0-9]/g, "");
            delete item.url;
          } else if (item.type == "URL") {
            delete item.phone_number;
          }
        });
      }

      // console.log("this.template.buttonData: ", this.template.buttonsData);

      let componentData;

      // Initial component data

      componentData = [
        {
          type: "BODY",
          text: JSON.parse(this.template.rawData),
        },
      ];

      // Check header is image or text

      if (this.template.type == "image") {
        componentData.push({
          type: "HEADER",
          format: "image",
        });
      } else {
        if (this.template.header.length) {
          componentData.push({
            type: "HEADER",
            format: "TEXT",
            text: this.template.header,
          });
        }
      }

      // Check if footer is present

      if (this.template.footer.length) {
        componentData.push({
          type: "FOOTER",
          text: this.template.footer,
        });
      }

      // Check if buttons are there

      if (
        this.template.buttons != null &&
        this.template.buttons != "none" &&
        this.template.buttonsData.length
      ) {
        componentData.push({
          type: "BUTTONS",
          buttons: this.template.buttonsData,
        });
      }

      let imageData;

      // If image exists then upload to our google cloud and create an attachment

      if (this.template.type == "image") {
        imageData = await this.uploadFile(this.fileToUpload);
        console.log("attachment data: ", imageData);
        await new Promise((resolve) => setTimeout(resolve, 2000)); // 2 seconds delay
      }

      const data = {
        name: this.template.title.trim(),
        components: componentData,
        language: "en_US",
        category: this.selectedItemCategory.title,
        variables: this.template.variables,
        image: this.template.type == "image" ? imageData : "",
      };

      console.log("data sending: ", JSON.stringify(data));

      if (this.template.text.length) {
        self.$http
          .post(`${this.ENDPOINT}/whatsapps/setTemplate`, data)
          .then((response) => {
            console.log("response", response);
            if (response.data.name == "Error") {
              this.$swal({
                type: "error",
                text: response.data.message,
              });
              self.loader = false;
              return;
            }
            this.$swal({
              type: "success",
              text: "Template created successfully and sent for approval",
            }).then((result) => {
              self.loader = false;
              if (result.value) {
                self.$router.go(-1);
              }
            });
          })
          .catch((e) => {
            console.log("e", e);
            self.loader = false;
            return this.$swal({
              type: "error",
              text: "An error occured! Please try again",
            });
          });
      } else {
        self.loader = false;
        return this.$swal({
          type: "error",
          text: "Message body cannot be blank",
        });
      }
    },
    validateStep1() {
      if (
        this.selectedItemCategory !== null &&
        this.template.title.trim() !== ""
      ) {
        const regex = /^[a-z_]+$/;
        if (!regex.test(this.template.title.trim())) {
          return this.$swal({
            type: "error",
            text:
              "Template title must be lowercase and can contain underscores only.",
          });
        } else {
          this.e1 = 2; // Proceed to step 2
        }
      } else {
        // Show validation error message or handle validation failure
        return this.$swal({
          type: "error",
          text: "Please select category and name",
        });
      }
    },
    validateStep2() {
      if (template.text !== "") {
        this.e1 = 3; // Proceed to step 3
      } else {
        // Show validation error message or handle validation failure
        return this.$swal({
          type: "error",
          text: "Please select category and name",
        });
      }
    },
    goBack() {
      this.e1 = 1;
    },
    isSelected(item) {
      return this.selectedItemCategory === item;
    },
    getAllCustomFields() {
      this.allCustomFields = [...this.fieldList];
      // for (var i = 0; i < this.allCustomFields.length; i++) {
      //   // Datatable Header
      //   this.fields.push(this.allCustomFields[i].name);
      // }
    },

    compileText(newText) {
      // Synchronize and reorder variables before processing
      // this.synchronizeVariables(this.template.text);
      // this.reorderAndSyncText(); // Ensure variables are in order if necessary

      // // Replace template variables with their values or keep the placeholder
      // this.template.text = this.template.text.replace(/\{\{(\d+)\}\}/g, (match, index) => {
      //   const variable = this.template.variables.find(v => v.index === index);
      //   return variable && variable.value ? variable.value : match;
      // });

      // console.log("template text: ", newText);

      // Process bold text
      let text =
        newText && newText.replace(/\*([^*]+)\*/g, "<strong>$1</strong>");

      // Handle line breaks
      text = this.formatForHtml(text);

      // console.log("template text: ", this.template.text);
      // console.log("template raw data: ", this.template.rawData);

      return text;
    },
    customValue() {
      var data = "";
      // Agents information
      if (this.selectedField == "Agent's Name") {
        data = "${agentName}";
      }
      if (this.selectedField == "Agent's Phone") {
        data = "${agentPhone}";
      }
      if (this.selectedField == "Agent's Email") {
        data = "${agentEmail}";
      }

      if (this.selectedField == "Phone") {
        data = "${phone}";
      }
      if (this.selectedField == "Name") {
        data = "${name}";
      }
      if (this.selectedField == "Email") {
        data = "${email}";
      }
      if (this.selectedField == "Remarks") {
        data = "${remarks}";
      }
      if (this.selectedField == "Company") {
        data = "${companyName}";
      }

      if (this.selectedField == "Note") {
        data = "${note}";
      }

      // Check & split
      var singleField = _.find(this.allCustomFields, (field) => {
        return field.name.toLowerCase() == this.selectedField.toLowerCase();
      });
      if (singleField) {
        data = "${" + `${singleField.name.toLowerCase()}` + "}";
      }

      this.insertAtCaret(data);
    },

    insertAtCaret(text) {
      var txtarea = "";
      if (this.template.type == "link") {
        txtarea = this.$refs.linkArea.$refs.input;
      } else {
        txtarea = this.$refs.templateArea.$refs.input;
      }

      var scrollPos = txtarea.scrollTop;
      var strPos = 0;
      var br =
        txtarea.selectionStart || txtarea.selectionStart == "0"
          ? "ff"
          : document.selection
          ? "ie"
          : false;

      if (br == "ie") {
        txtarea.focus();
        var range = document.selection.createRange();
        range.moveStart("character", -txtarea.value.length);
        strPos = range.text.length;
      } else if (br == "ff") strPos = txtarea.selectionStart;
      var front = txtarea.value.substring(0, strPos);
      var back = txtarea.value.substring(strPos, txtarea.value.length);
      txtarea.value = front + text + back;

      this.template.text = front + text + back;

      strPos = strPos + text.length;
      if (br == "ie") {
        txtarea.focus();
        range = document.selection.createRange();
        range.moveStart("character", -txtarea.value.length);
        range.moveStart("character", strPos);
        range.moveEnd("character", 0);
        range.select();
      } else if (br == "ff") {
        txtarea.selectionStart = strPos;
        txtarea.selectionEnd = strPos;
        txtarea.focus();
      }
      txtarea.scrollTop = scrollPos;
    },
    // Get all templates
    getTemplateBy(id) {
      this.preloader = true;
      this.template = {};

      this.$http
        .get(`${this.$store.state.ENDPOINT}/templates/${id}`)
        .then((response) => {
          this.template = response.body;
          this.gotType(this.template.type);
          if (typeof this.template.isLink != "undefined") {
            if (this.template.isLink) {
              this.gotType("link");
            } else {
              this.gotType("text");
            }
            delete this.template.isLink;
          }
          if (this.template.att) {
            this.getImage(this.template.att);
          }
          this.preloader = false;
        })
        .catch((e) => {
          this.preloader = false;
          if (e.body.error && e.body.error.message) {
            this.displayMessage({
              type: "error",
              text: e.body.error.message,
            });
            return;
          }
          this.displayMessage({
            type: "error",
            text: "Something went wrong while fetching template data.",
          });
        });
    },
    // Delete
    async deleteTemplate(id) {
      try {
        if (this.template.attId) {
          try {
            await this.$http.delete(
              `${this.$store.state.ENDPOINT}/attachments/${this.template.attId}`
            );
          } catch (e) {
            console.log(e);
          }
        }
        var response = await this.$http.delete(
          `${this.$store.state.ENDPOINT}/templates/${id}`
        );
        this.$router.replace({ path: "/whatsapp-templates" });
      } catch (e) {
        if (e.body.error && e.body.error.message) {
          //open snakbar to show errors
          this.displayMessage({ text: e.body.error.message, type: "warning" });
          return;
        }
        this.displayMessage({
          text: "Something went wrong while deleting this template",
          type: "warning",
        });
      }
    },
    // display the message
    displayMessage({ text, type }) {
      this.snackbar = false;
      var color;
      switch (type) {
        case "success":
          color = "#007E33";
          break;
        case "warning":
          color = "#FF8800";
          break;
        case "error":
          color = "#CC0000";
          break;
      }
      this.color = color;
      this.text = text;
      this.snackbar = true;
    },

    async getImage(src) {
      if (src) {
        this.attachmentURL = `${this.$store.state.ENDPOINT}/storages/get/${src}?access_token=${this.$store.getters.user.token}`;
      }
    },

    async onFileSelect(file) {
      console.log("got file: ", file);
      const compressor = (file) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (event) => {
            compress(event.target.result, {
              width: 512,
              type: "image/png", // default
              max: 2048, // max size
              min: 2, // min size
              quality: 0.5,
            })
              .then((result) => {
                resolve(result);
              })
              .catch((e) => {
                this.loader = false;
                console.log("err", e);
              });
          };
          reader.onerror = (error) => reject(error);
        });

      function srcToFile(src, fileName, mimeType) {
        return fetch(src)
          .then(function(res) {
            return res.arrayBuffer();
          })
          .then(function(buf) {
            return new File([buf], fileName, { type: mimeType });
          });
      }

      const self = this;
      self.fileName = file.name;
      self.mimeType = file.type;
      self.loader = true;

      console.log("file data: ", self.fileName, self.mimeType);

      var compressed = await compressor(file);
      self.attachmentURL = compressed;
      srcToFile(self.attachmentURL, self.fileName, self.mimeType).then(
        async (file2Upload) => {
          self.fileToUpload = file2Upload;
          console.log("file to upload: ", self.fileToUpload);
          self.loader = false;
        }
      );

      console.log("attachmentURL: ", this.attachmentURL);
    },
    async removeImage() {
      this.attachmentURL = "";
      // this.template.att = null;
      // this.template.attId = null;
    },

    selectType() {
      if (this.template.type == "link") {
        this.template.text = "https://yourlink.com?phone=${phone}";
      } else {
        this.template.text = "Hey ${name}, thank you for choosing GoDial.";
      }
    },
    gotType(type) {
      this.template.type = type;
      for (var each in this.types) {
        if (this.types[each].name != type) {
          this.types[each].selected = false;
        } else {
          this.types[each].selected = true;
        }
      }
    },
    onChange() {
      this.filelist = [...this.$refs.file.files];
      this.onFileSelect(this.filelist[0]);
    },
    remove() {
      this.filelist = [];
      this.attachmentURL = "";
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    // decode()
    // {
    //   try
    //   {
    //     if(this.updateId != "add")
    //   {
    //     this.template.text=decodeURIComponent(this.template.text);
    //   }
    //   //console.log(decodeURIComponent(this.template.text));
    // }
    // catch(e)
    // {
    //   console.log("error", e)
    // }
    // }
  },
};
</script>

<style scoped>
[v-cloak] {
  display: none;
}

.btn-component {
  width: 90%;
}

.chat-card {
  position: relative;
  /* Ensure the pseudo-element is positioned relative to this element */
  background-color: #d6fad6;
  /* border-radius: 16px; */
  padding: 0.5rem;
  margin-bottom: 12px;
  width: 20vw;
  max-width: 20rem;
  min-width: 15rem;
}

.chat-card::before {
  content: "";
  position: absolute;
  top: 20%;
  left: -16px;
  /* Adjust this value to position the arrow */
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 16px 8px 0;
  /* Adjust the size of the arrow */
  border-color: transparent #d6fad6 transparent transparent;
  /* Match the background color of the card */
  transform: translateY(-50%);
}

.chat-footer {
  font-size: 0.6rem;
  color: #999999;
}

.chat-text {
  font-size: 0.8rem;
  /* line-height: 1.4; */
  /* word-wrap: break-word; */
  padding: 0.3rem 0;
}

.chat-actions {
  text-align: right;
  display: flex;
  align-items: space-between;
  justify-content: center;
  flex-direction: column;
}

.chat-timestamp {
  margin: 5px 0;
  font-size: 12px;
  color: #999999;
}

.c-blue {
  color: #5890ff;
  text-transform: capitalize;
}

.close-btn {
  width: 10%;
}

.custom-width {
  width: 100%;
}

.custom-btn-width {
  width: 20%;
  max-width: 3rem;
}

/* .custom {
  all: unset;
} */

.image-preview {
  width: 100%;
  height: auto;
  overflow: hidden;
  /* border: 1px solid black; */
  border-radius: 4px;
  padding: 0.3rem;
  background-color: white;
}

.image-preview img {
  object-fit: cover;
  width: 100%;
}

.radio-card {
  position: relative;
  border-radius: 16px;
  padding: 12px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
}

.radio-input {
  display: none;
}

.radio-label {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
}

.radio-label v-icon {
  margin-right: 20px;
  /* Adjust the spacing between the icon and the text */
}

.stepper {
  box-sizing: border-box;
  width: 100%;
  overflow: visible;
}

.tel-input {
  height: 100%;
  min-height: 3.5rem;
}

.text-style {
  font-size: 0.8rem;
}

.value-label {
  width: 15%;
}

.value-select {
  max-height: 3rem;
  /* width: auto; */
  overflow: hidden;
  border: inherit;
}

.description {
  padding-top: 2%;
  margin: 0;
}
</style>
